import { getCookie } from "./getCookie";

export default () => {
  // Check if deeplink is set; if not generate a new one
  const deepLink = getCookie("brickwiseAppLink");
  if (deepLink) {
    setDeepLinks(deepLink);
  } else {
    let searchParams = new URLSearchParams(window.location.search);
    let googleClientId = getCookie("_ga") || "cookie_not_set";
    if (
      searchParams.has("utm_source") ||
      searchParams.has("utm_medium") ||
      searchParams.has("utm_campaign") ||
      searchParams.has("fbclid") ||
      googleClientId !== "cookie_not_set"
    ) {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.app.brickwise.at/api/v1/utils/marketingUrl", true);
      //Send the proper header information along with the request
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.responseType = "json";

      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          setDeepLinks(this.response.data.deepLink);
          checkConsentAndSetUrl(this.response.data.deepLink);
        }
      };

      let utm_source = "";
      let utm_medium = "";
      let utm_campaign = "";
      if (searchParams.has("fbclid")) {
        searchParams.set("fbclid", `fb.1.${+new Date()}.${searchParams.get("fbclid")}`);
        searchParams.has("utm_source") ?? searchParams.set("utm_source", "facebook");
        searchParams.has("utm_medium") ?? searchParams.set("utm_medium", "cpc");
        searchParams.has("utm_campaign") ?? searchParams.set("utm_campaign", "fbclid");
      }
      utm_source = searchParams.get("utm_source");
      utm_medium = searchParams.get("utm_medium");
      utm_campaign = searchParams.get("utm_campaign");

      xhr.send(
        `utmSource=${utm_source}&utmMedium=${utm_medium}&utmCampaign=${utm_campaign}${
          searchParams.has("fbclid") ? "&fbclid=" + searchParams.get("fbclid") : ""
        }${
          searchParams.has("gclid") ? "&gclid=" + searchParams.get("gclid") : ""
        }&googleClientId=${googleClientId}`
      );
    }
  }

  function saveDeepLinkUrl(url) {
    const d = new Date();
    // Session is there for 30 mins
    d.setTime(d.getTime() + 30 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie =
      "brickwiseAppLink" + "=" + url + ";" + expires + ";path=/";
  }

  function checkConsentAndSetUrl(url) {
    // const brickwiseCookieConsentgGtm = getCookie("brickwiseCookieConsentgGtm");
    // const { ad_storage, analytics_storage, personalization_storage } = JSON.parse(brickwiseCookieConsentgGtm);
    // if (analytics_storage || ad_storage || personalization_storage)
    // We are not checking consent here
    saveDeepLinkUrl(url)
  }

  function setDeepLinks(deepLink) {
    if (document.getElementById("cta_download_mobile"))
      document.getElementById("cta_download_mobile").href = deepLink;
    if (document.getElementById("cta_menu"))
      document.getElementById("cta_menu").href = deepLink;
    if (document.getElementById("cta_second_div"))
      document.getElementById("cta_second_div").href = deepLink;
    if (document.getElementById("cta_general_div"))
      document.getElementById("cta_general_div").href = deepLink;
    if (document.getElementById("cta_how-it-works"))
      document.getElementById("cta_how-it-works").href = deepLink;
    if (document.getElementById("cta_ios_footer_mobile"))
      document.getElementById("cta_ios_footer_mobile").href = deepLink;
    if (document.getElementById("cta_android_footer_mobile"))
      document.getElementById("cta_android_footer_mobile").href = deepLink;
    if (document.getElementById("cta_below_the_fold_bonus"))
      document.getElementById("cta_below_the_fold_bonus").href = deepLink;
    if (document.getElementById("cta_android_logo"))
      document.getElementById("cta_android_logo").href = deepLink;
    if (document.getElementById("cta_ios_logo"))
      document.getElementById("cta_ios_logo").href = deepLink;
    if (document.getElementById("cta_download_app"))
      document.getElementById("cta_download_app").href = deepLink;
      
  }
};
