import loadTagmanager from "./loadTagmanager";
import cookieConsent from "./cookieConsent";
import generateDeeplinks from "./generateDeeplinks";

(() => {

  $(document).ready(function () {
    console.log('Load Header Module')
    const environment = window.location.hostname.includes("brickwise.webflow.io")
    ? "development"
    : "production";
    loadTagmanager(environment);

    Webflow.push(function () {
      console.log('Load Header Module cookies')
      $(document).ready(function () {
        cookieConsent(generateDeeplinks);
      });
    });

    Webflow.push(function () {
      // console.log("Webflow push");
      $(document).ready(function () {
        generateDeeplinks();
      });
    });
    console.log("End");
  })
})();
